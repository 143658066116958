import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'

import MOCK_DATA from './mock/MOCK_DATA.json';
import PostCard from './community/PostCard';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://m-a-s-e.it/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.



export default function CommunityPosts() {
  const [categories, setCategories] = React.useState([
    {id: 1, name: "Imbarchi e Armatori", image: "https://staticgeopop.akamaized.net/wp-content/uploads/sites/32/2022/09/navi-crociera-piu-grandi.jpg"},
    {id: 2, name: "Problemi di coperta", image: "https://www.giornaledellavela.com/wp-content/uploads/2022/11/D.First-44-Pozzetto.jpeg"}
  ]);
  return (
    <Grid container sx={{width: '100%'}}>
      {MOCK_DATA.map(x => (
        <Grid xs={12} sx={{height: 200, mx: 5, marginTop: 8, width: 'full', position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', background: `url(${x.image})`}}>
              <PostCard content={x} />
        </Grid>
      ))}
    </Grid>
      
  );
}
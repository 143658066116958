import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from './Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadIMO from './LoadIMO';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';

const style = {
    card: {
        width: 290,
        minHeight: 290,
        backgroundColor: '#fff',
        color: '#1B2B58',
        boxShadow: '0px 0px 17px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: 23,
        flexShrink: 0,
        marginTop: '2rem',
        position: 'relative'
    },
    widget: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#555',
        width: '100%'
    },
    singleWidget: {
        flexDirection: 'column',
        display: 'flex'
    }
}


const Card = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [certificate, setCertificate] = useState("");
    const [loadIMO, setLoadIMO] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setCertificate(item);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const popoverRef = useRef();

    const [popover, setPopover] = useState(null);

    useEffect(() => {
        
    }, [])
    return (
    <>
    {loadIMO && <LoadIMO role={props.role}/>}
    <div style={style.card}>
        {props.info?.length > 0 && <div style={{position: 'absolute', right: 12, top: 12}}>

<div onClick={(e) => setPopover(e.currentTarget)}>
    <InfoIcon />
</div>
<Popover
id={'info-popover'}
open={Boolean(popover)}
anchorEl={popover}
onClose={() => setPopover(null)}
anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
}}
>
    <Typography sx={{ p: 2 }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {props.info && Object.keys(props.info?.reduce((acc, obj) => {
            const key = obj.title;
            if(!acc[key])acc[key] = [];
            acc[key].push(obj);
            return acc;
        }, {})).map(x => (
            <>
             <strong>{x}:</strong>
             <ul>
                {props.info?.filter(y => y.title === x).map(
                    y => <li>{y.description}</li>
                )}
             </ul>
            </>
           
        ))}
        </div>
        
    </Typography>
</Popover>
</div>}
        
        <a href="https://drive.google.com/file/d/1wqQm4G3dMUfzbnJZ3zMPaouOsxVfQcpQ/view?usp=sharing" id="examPdf" target="_blank" style={{visibility: 'hidden'}}>Apri Esame</a>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingBottom: 12}}>
            <div>{props.icon}</div>
            <div style={{marginLeft: '1.5rem'}}>
                <h2 style={{fontSize: '38px', minHeight: 54, marginBottom: 0, marginTop: 0}}>{props.durability} mesi</h2>
                <Typography textTransform={'uppercase'} justifyContent={"center"} margin="0 auto" marginTop="5px" color="#626262" fontFamily="Roboto" textAlign="left" fontSize="14px" >
                    {props.role['name'] !== 'Istruttore Centro Formazione' ? props.description : 'di navigazione negli ultimi 5 anni'}
                </Typography>
            </div>
        </div>
        {props.additional && Object.keys(props.additional).length > 0 && (
            <>
                <hr/><strong style={{textAlign: 'center', margin: '0 auto'}}>Oppure</strong><br/>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingBottom: 32}}>
                    <div>
                        <div style={{width: 95, height: 95}}><CircularProgressbar strokeWidth={5} styles={buildStyles({
                                strokeLinecap: 'butt',
                                textSize: '31px',
                                pathTransitionDuration: 0.5,
                                pathColor: `#1B2B58`,
                                textColor: '#1B2B58',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                            })} value={props.additional?.percentage} text={`${props.additional?.percentage}%`}/>
                        </div>
                    </div>
                    <div style={{marginLeft: '1.5rem'}}>
                        <h2 style={{fontSize: '38px', minHeight: 54, marginBottom: 0, marginTop: 0}}>{props.additional?.remainingMonths} mesi</h2>
                        <Typography textTransform={'uppercase'} justifyContent={"center"} margin="0 auto" marginTop="5px" color="#626262" fontFamily="Roboto" textAlign="left" fontSize="14px" >
                            {`Di navigazione di cui ${parseInt(props.additional?.object.navigationMonths) - parseInt(props.additional?.grtMonths) >= 0 ? parseInt(props.additional?.object.navigationMonths) - parseInt(props.additional?.grtMonths) : 0 } come ${props.additional?.role?.name ? props.additional?.role?.name : null} ${!isNaN(props.additional?.object?.grt?.replace('Kw').trim()) ? `su navi pari o superiori a ${props.additional?.object?.grt.includes('Kw') ? props.additional?.object?.grt : `${props.additional?.object?.grt} tonnellate` }`: ''}`}
                        </Typography>
                    </div>
                </div>
            </>

        )}

        <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginBottom: '0.5rem'}}></div>
        {/* <hr /> */}
        {props.certificates.length === 0 && props.role.name.toLowerCase() !== 'pilota di porto' && (props.durability <= 0 || props.additional?.remainingMonths <= 0)  && props.exam && !localStorage.getItem('examSeen') && props.role?.['name'].includes('Allievo') && <p>Per effettuare l'esame bisogna farsi vidimare dalla capitaneria il Cadet Record Book.</p>}
        {props.certificates.length === 0 && props.durability > 0 && props.additional?.remainingMonths > 0 && <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >Completa i mesi di navigazione per passare di grado</Typography>}
        {props.certificates.length === 0 && props.role.name.toLowerCase() !== 'pilota di porto' && (props.durability <= 0 || props.additional?.remainingMonths <= 0) && props.exam && !localStorage.getItem('examSeen') && <CustomButton onClick={() => {
            if(props.role.role_required.name.toLowerCase().includes('allievo') && !props.documents?.includes('Cadet Record Book')){
                alert("Bisogna caricare il Cadet Record Book per sbloccare l'esame.");
                return;
            }
            window.open("https://drive.google.com/file/d/1wqQm4G3dMUfzbnJZ3zMPaouOsxVfQcpQ/view?usp=sharing");
        }} style={{margin: '0 auto', marginTop: 12}} inverse text="Scarica modulo esame" />}
        {props.certificates.length === 0 && props.role.name.toLowerCase() !== 'pilota di porto' && (props.durability <= 0 || props.additional?.remainingMonths <= 0)  && props.exam && !localStorage.getItem('examSeen') && <CustomButton onClick={() => {
            if(props.role.role_required.name.toLowerCase().includes('allievo') && !props.documents?.includes('Cadet Record Book')){
                alert("Bisogna caricare il Cadet Record Book per sbloccare l'esame.");
                return;
            }
            localStorage.setItem('examSeen', true);
            window.open(props.url);
        }} style={{margin: '0 auto', marginTop: 12}} inverse text="Visualizza date esame" />}
        {props.certificates.length === 0 && props.role.name.toLowerCase() === 'pilota di porto' && (props.durability <= 0 || props.additional?.remainingMonths <= 0)  && props.exam  && !localStorage.getItem('examSeen') && <CustomButton onClick={() => {
            window.open(props.url);
        }} style={{margin: '0 auto', marginTop: 12}} inverse text="Date concorsi Fedepiloti" />}
        {props.certificates.length === 0 && (props.durability <= 0 || props.additional?.remainingMonths <= 0)  && !props.exam  && <CustomButton onClick={() => props.buttonAction()} style={{margin: '0 auto', marginTop: 12}} inverse text="Passa al grado successivo" />}
        {props.certificates.length === 0 && (props.durability <= 0 || props.additional?.remainingMonths <= 0) && props.exam && localStorage.getItem('examSeen') && <CustomButton onClick={() => setLoadIMO(true)} style={{margin: '0 auto', marginTop: 12}} inverse text="Passa al grado successivo" />}
        {props.certificates.map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
                <Typography onClick={(e) => handleClick(e, item.id)} textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
            </div>
        {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
        </>
            
        ))}
         <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => props.onExternalLoad(certificate)}>Carica corso</MenuItem>
                    <MenuItem onClick={() => props.onFindSchool(certificate)}>Prenota il corso</MenuItem>
                </Menu>
    </div>
    </>);
    
}

export default Card;